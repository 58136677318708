import { useEffect, useState } from "react";
import { getTrucksApi } from "@/api/centerApi";
import { TRUCK_DATA_KEY } from "@/contants/contants";
import { startTimer, stopTimer } from "@/utils/formatTime";
import { getLocal, setLocal } from "@/utils/LocalStorage";
interface TrucksData {
  truck: string;
  plate: string;
  plate_col: string;
  truck_type: string;
}
interface TruckLocation {
  longitude: number;
  latitude: number;
}
interface Truck {
  username: string;
  loc: TruckLocation;
  trucks: TrucksData[];
}
interface ChartDataTruck {
  trucks: Truck[];
}
export const useTruckEffect = () => {
  const [chartDataTruck, setChartDataTruck] = useState<ChartDataTruck>({
    trucks: [],
  });

  const formatTruckValue = (data: Truck[]) => {
    const trucksData: ChartDataTruck = {
      trucks: data,
    };
    return trucksData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getTrucksApi();
        if (res.data.code === "0") {
          const formattedData = formatTruckValue(res.data.data);
          const localChartData = getLocal(TRUCK_DATA_KEY);
          if (
            JSON.stringify(formattedData) !== JSON.stringify(localChartData)
          ) {
            setChartDataTruck(formattedData);
            setLocal(TRUCK_DATA_KEY, formattedData);
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          if (
            error.message.includes("timeout") ||
            error.message === "Network Error"
          ) {
            /* empty */
          }
        }
      }
    };

    const localChartData = getLocal(TRUCK_DATA_KEY);
    if (localChartData) {
      setChartDataTruck(localChartData);
    }

    fetchData();
    startTimer(fetchData);

    return () => {
      stopTimer();
    };
  }, []);
  const getTruckEndEffect = () => {
    return {
      name: "货车分布",
      type: "scatter",
      symbol: "image://" + require("@/assets/image/truck.png"),
      coordinateSystem: "geo",
      symbolSize: 15,
      zlevel: 1,
      tooltip: {
        trigger: "item",
        show: true,
        backgroundColor: "transparent",
        borderColor: "transparent",
        formatter: function (params: {
          data: { username: string; trucks: TrucksData[] };
        }): string {
          const truckData = params.data;
          const plate = truckData.trucks[0] ? truckData.trucks[0].plate : null;
          const isTruckDataAvailable =
            truckData.trucks[0] !== undefined && truckData.trucks[0] !== null;

          const txt = `
            <div style="
            background: rgba(0, 179, 244, 0.7);
            border: 2px solid #2BEDF6;
            border-radius: 8px;
            padding: 10px;
            color: #2BEDF6;
            box-shadow: 0 0 10px rgba(0, 179, 244, 0.5);
            font-family: 'Orbitron', sans-serif;
            text-align: left;
            ">
            <h2 style="color:#00B3F4 margin: 10px 0 20px 0;text-align: center;">货车详情</h2>
            <p style="color: #fff; margin: 8px 0;">用户名：${
              truckData.username
            }</p>
            ${
              isTruckDataAvailable
                ? `<p style="color: #fff; margin: 8px 0;">车牌：${plate}</p>`
                : `<p style="color: #fff; margin: 8px 0;">车牌：未填</p>`
            }
            ${
              isTruckDataAvailable
                ? `  <p style="color: #fff; margin: 8px 0;">车颜色：${truckData.trucks[0].plate_col}</p>`
                : `<p style="color: #fff; margin: 8px 0;">车颜色：未填</p>`
            }
            ${
              isTruckDataAvailable
                ? `    <p style="color: #fff; margin: 8px 0;">车型：${truckData.trucks[0].truck_type}</p>`
                : `<p style="color: #fff; margin: 8px 0;">车型：未填</p>`
            }
            </div>
            `;
          return `
             ${txt}
          `;
        },
      },
      rippleEffect: {
        brushType: "stroke",
      },
      data: chartDataTruck.trucks.map((item) => {
        return {
          username: item.username,
          value: [item.loc.longitude, item.loc.latitude],
          trucks: item.trucks.map((truckItem) => {
            return {
              truck: truckItem.truck,
              plate: truckItem.plate,
              plate_col: truckItem.plate_col,
              truck_type: truckItem.truck_type,
            };
          }),
        };
      }),

      color: "#fff",
      showEffectOn: "render",
      itemStyle: {
        normal: {
          color: "#B6A2DE",
        },
      },
    };
  };
  const truckEffect = getTruckEndEffect();
  return { truckEffect };
};
