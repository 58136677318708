// 日期
export const Util = {
  formateDate(time: number) {
    if (!time) return "";
    const date = new Date(time);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  },
};

// 倒计时
let intervalId: NodeJS.Timeout;

export const startTimer = (callback: () => void, interval = 30000): void => {
  intervalId = setInterval(callback, interval);
};

export const stopTimer = (): void => {
  clearInterval(intervalId);
};
