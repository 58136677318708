import request from "@/utils/request";

// 订单和货物
export function getCommonApi() {
  return request({
    url: "/microSer/statistics2/common",
    method: "GET",
  });
}

// 订单和货物详情
export function getDetailApi(id: string, type: number) {
  return request({
    url: `/microSer/statistics2/common/detail?id=${id}&type=${type}`,
    method: "GET",
  });
}

// 货车分布
export function getTrucksApi() {
  return request({
    url: "/microSer/locationTracking2/trucks",
    method: "GET",
  });
}

// 货主分布
export function getUsersRoleApi(role: number) {
  return request({
    url: `/microSer/locationTracking2/users?role=${role}`,
    method: "GET",
  });
}
