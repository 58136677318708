import Keycloak from 'keycloak-js';

class KeycloakManager {
  private static instance: Keycloak;

  static setInstance(keycloak: Keycloak) {
    KeycloakManager.instance = keycloak;
  }

  static getInstance() {
    return KeycloakManager.instance;
  }
}

export default KeycloakManager;