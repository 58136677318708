import axios from "axios";
import KeycloakManager from "./keycloakManager";

const service = axios.create({
  baseURL: "https://apisix.guiyunbao.cn",
  timeout: 50000,
});


service.interceptors.request.use(
  (config) => {
    const keycloak = KeycloakManager.getInstance();
    config.headers['Authorization'] = `Bearer ${keycloak.token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
