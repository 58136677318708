import request from "@/utils/request";

// 基础数据
export function getBasicApi() {
  return request({
    url: "/microSer/statistics2/orderStats/basic",
    method: "GET",
  });
}

// 用户活跃
export function getOnlStatsApi() {
  return request({
    url: "/microSer/statistics2/onlStats?range=15",
    method: "GET",
  });
}

// 发货统计
export function getCargoStatsApi() {
  return request({
    url: "/microSer/statistics2/cargoStats?range=15",
    method: "GET",
  });
}
