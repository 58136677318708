import styled from "styled-components";

export const MapBox = styled.div`
  .top-content {
    margin: 0.625rem 0.625rem 0;
  }
  .map {
    position: relative;
    line-height: 50.625rem;
  }
  .bottom-content {
    display: flex;
  }
`;

export const ChatBotBox = styled.div`
  .chatbot {
    width: 100%;
    height: 323px;
    padding: 0 15px;
  }
`;
