import * as echarts from "echarts";
// 订单状态(月)
export const OrderStatusChartOption = (chartData: { name: string }[]) => {
  return {
    animation: false,
    title: {
      text: "订单状态(月)",
      x: "left",
      left: "5%",
      top: "5%",
      textStyle: {
        color: "rgba(0,179,244)",
        fontWeight: "bold",
      },
    },
    color: [
      "#32DADD",
      "#FBE30E",
      "#FFCB8C",
      "#ED868C",
      "#B0E57C",
      "#00B3F4",
      "#C8B2F4",
      "#FFD700",
    ],
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
      backgroundColor: "rgba(0,179,244,0.5)",
    },
    series: [
      {
        name: "订单状态(月)",
        type: "pie",
        radius: ["30%", "55%"],
        center: ["50%", "55%"],
        data: chartData,
        avoidLabelOverlap: true,
        labelLine: {
          length: 15,
          length2: 25,
          maxSurfaceAngle: 150,
        },
        label: {
          formatter: "{name|{b}}\n{value|{d}% - {c}单}",
          textStyle: {
            fontSize: 15,
            fontWeight: "bold",
          },
          minMargin: 5,
          edgeDistance: 10,
          lineHeight: 15,
          rich: {
            value: { fontSize: 12, fontWeight: "bold" },
          },
        },
      },
    ],
  };
};

// 注册量(周)
export const NumberRegistrationsChartOption = (chartData: {
  date: (string | number)[];
  registration: (string | number)[];
  verification: (string | number)[];
}) => {
  return {
    animation: false,
    title: {
      text: "注册量(周)",
      x: "left",
      left: "5%",
      top: "5%",
      textStyle: {
        color: "rgba(0,179,244)",
        fontWeight: "bold",
      },
    },
    grid: {
      top: "85",
      bottom: "30",
      containLabel: true,
    },
    legend: {
      right: "10%",
      top: "5%",
      textStyle: {
        color: "#c0c9d2",
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      textStyle: {
        color: "#fff",
      },
      backgroundColor: "rgba(0,179,244,0.5)",
    },
    xAxis: {
      type: "category",
      data: chartData.date,
      inverse: true,
    },
    yAxis: {
      splitArea: false,
      axisLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: "rgba(0,179,244,0.9)",
        },
      },
    },
    series: [
      {
        name: "已注册",
        type: "bar",
        data: chartData.registration,
        label: {
          position: "top",
          show: true,
          textStyle: {
            color: "#2EC7C9",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
      },
      {
        name: "已验证",
        type: "bar",
        data: chartData.verification,
        itemStyle: {
          color: "#00B3F4",
        },
        label: {
          position: "top",
          show: true,
          textStyle: {
            color: "#00B3F4",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
      },
    ],
  };
};
// 接单统计(15天)
export const TakeOrdersChartOption = (chartData: {
  orderStats: number[];
  date: string[];
}) => {
  return {
    animation: false,
    title: {
      text: "接单统计(15天)",
      x: "left",
      left: "5%",
      top: "5%",
      textStyle: {
        color: "rgba(0,179,244)",
        fontWeight: "bold",
      },
    },

    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      textStyle: {
        color: "#fff",
      },
      backgroundColor: "rgba(0,179,244,0.5)",
    },
    grid: {
      top: "85",
      bottom: "30",
      containLabel: true,
    },

    legend: {
      right: "10%",
      top: "5%",
      textStyle: {
        color: "#c0c9d2",
      },
    },
    xAxis: {
      type: "category",
      data: chartData.date,
      boundaryGap: false,
      inverse: true,
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      splitArea: false,
      axisLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: "rgba(0,179,244,0.9)",
        },
      },
    },
    series: [
      {
        name: "已选定",
        data: chartData.orderStats,
        type: "line",
        smooth: true,
        itemStyle: { color: "#00b3f4" },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(
            0,
            0,
            0,
            1,
            [
              {
                offset: 0,
                color: "rgba(0,179,244,0.3)",
              },
              {
                offset: 1,
                color: "rgba(0,179,244,0.1)",
              },
            ],
            false
          ),
          shadowColor: "rgba(0,179,244,0.9)",
          shadowBlur: 20,
        },
        label: {
          show: true,
          position: "top",
          textStyle: {
            color: "#00B3F4",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        lineStyle: {
          color: "#00b3f4",
          width: 2,
        },
      },
    ],
  };
};
