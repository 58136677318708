import { useState, useEffect } from "react";
import { Decoration10, Decoration8 } from "@jiaminghi/data-view-react";
import { Util } from "@/utils/formatTime";
import { TopPage } from "./style";
import { getChatbotagreeApi } from "@/api/chatbotAPi";
import { startTimer, stopTimer } from "@/utils/formatTime";
import { Satisfaction_KEY } from "@/contants/contants";
import { getLocal, setLocal } from "@/utils/LocalStorage";

interface ChatData {
  agree: number;
}

interface FormattedData {
  agree: number;
}

function TopSidebar() {
  const [currentTime, setShowTime] = useState<string>("");
  const [chatData, setChartData] = useState<ChatData>({ agree: 0 });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const sysTime = Util.formateDate(new Date().getTime());
      setShowTime(sysTime);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const chatBotAgree = (data: FormattedData) => {
    const formattedData: FormattedData = {
      agree: data.agree,
    };
    return formattedData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getChatbotagreeApi();
        if (res.data.tig === "success") {
          const formattedData = chatBotAgree(res.data.data);
          const localChartData = getLocal(Satisfaction_KEY);
          if (
            JSON.stringify(formattedData) !== JSON.stringify(localChartData)
          ) {
            setChartData(formattedData);
            setLocal(Satisfaction_KEY, formattedData);
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          if (
            error.message.includes("timeout") ||
            error.message === "Network Error"
          ) {
            /* empty */
          }
        }
      }
    };

    const localChartData = getLocal(Satisfaction_KEY);
    if (localChartData) {
      setChartData(localChartData);
    }

    fetchData();
    startTimer(fetchData);

    return () => {
      stopTimer();
    };
  }, []);

  return (
    <TopPage>
      <div className="top-sidebar-container">
        <div className="left-decorations">
          <div className="chatBot">
            智能客服满意度: {Number(chatData.agree) * 100}%
          </div>
          <Decoration10
            reverse={true}
            style={{ width: "21.875rem", height: ".3125rem" }}
          />
          <Decoration8 style={{ width: "18.75rem", height: "3.125rem" }} />
        </div>
        <div className="center-content">
          <h3>桂运宝数据可视化平台</h3>
        </div>
        <div className="right-decorations">
          <Decoration8
            reverse={true}
            style={{ width: "18.75rem", height: "3.125rem" }}
          />
          <Decoration10
            style={{
              width: "21.875rem",
              height: ".3125rem",
              transform: "rotateY(180deg)",
            }}
          />
          <div className="current-time">当前时间: {currentTime}</div>
        </div>
      </div>
    </TopPage>
  );
}

export default TopSidebar;
