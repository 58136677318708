import Chatbot from "./echarts/chatbot";
import Map from "./echarts/Map";
import { MapBox } from "./style";
function CenterPage() {
  return (
    <div>
      <MapBox>
        <div className="top-content">
          <div className="map">
            <Map></Map>
          </div>
        </div>
        <div className="bottom-content">{/* <Chatbot /> */}</div>
      </MapBox>
    </div>
  );
}

export default CenterPage;
