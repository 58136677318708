import request from "@/utils/request";

// 注册量
export function getRegistrationApi() {
  return request({
    url: "/microSer/statistics2/userStats?range=7",
    method: "GET",
  });
}

// 订单状态
export function getOrderApi() {
  return request({
    url: "/microSer/statistics2/orderStats/status",
    method: "GET",
  });
}

// 接单统计
export function getOrderStatsApi() {
  return request({
    url: "/microSer/statistics2/orderStats?range=15",
    method: "GET",
  });
}
