import { useEffect, useState } from "react";
import * as echarts from "echarts";
import { BorderBox12 } from "@jiaminghi/data-view-react";
import "echarts/theme/macarons";
import { getOnlStatsApi } from "@/api/rightApi";
import { startTimer, stopTimer } from "@/utils/formatTime";
import { setNotopt } from "@/utils/notopt";
import { User_Active_KEY } from "@/contants/contants";
import { UserActiveChartOption } from "./option";
import { getLocal, setLocal } from "@/utils/LocalStorage";

interface UserData {
  onlStats: number[];
  date: string[];
}
function ScrollRankingList() {
  const [chartData, setChartData] = useState<{
    onlStats: number[];
    date: string[];
  }>({
    onlStats: [],
    date: [],
  });
  const formatChartData = (data: UserData[]) => {
    const formattedData: { onlStats: number[]; date: string[] } = {
      onlStats: [],
      date: [],
    };
    const onlStatsData = data;
    for (let i = 0; i < onlStatsData.length; i += 2) {
      const rawDate = onlStatsData[i].toString();
      const formattedDate = `${rawDate.slice(0, 4)}-${rawDate.slice(
        4,
        6
      )}-${rawDate.slice(6, 8)}`;
      formattedData.date.push(formattedDate);
      formattedData.onlStats.push(
        (onlStatsData[i + 1] as unknown as number) + 20
      );
    }
    return formattedData;
  };

  useEffect(() => {
    const chartContainer = document.getElementById("scroll") as HTMLDivElement;
    const chart = echarts.init(chartContainer, "macarons");
    chart.showLoading();
    const fetchData = async () => {
      try {
        const res = await getOnlStatsApi();
        if (res.data.code === "0") {
          const formattedData = formatChartData(res.data.data);
          const localChartData = getLocal(User_Active_KEY);
          if (
            JSON.stringify(formattedData) !== JSON.stringify(localChartData)
          ) {
            setChartData(formattedData);
            setLocal(User_Active_KEY, formattedData);
          } else {
            chart.hideLoading();
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          if (
            error.message.includes("timeout") ||
            error.message === "Network Error"
          ) {
            chart.hideLoading();
          }
        }
      }
    };
    const localChartData = getLocal(User_Active_KEY);
    if (localChartData) {
      setChartData(localChartData);
    }
    fetchData();
    startTimer(fetchData);
    return () => {
      stopTimer();
    };
  }, []);

  useEffect(() => {
    const chartContainer = document.getElementById("scroll") as HTMLDivElement;
    const chart = echarts.init(chartContainer, "macarons");
    if (chartData.date.length < 1) {
      setNotopt(chart, "糟糕！用户活跃数据出现错误");
    } else {
      const option = UserActiveChartOption(chartData);
      chart.setOption(option as object);
    }
    return () => {
      chart.dispose();
    };
  }, [chartData]);

  return (
    <BorderBox12
      style={{
        width: "100%",
        height: "22.5rem",
        zIndex: 999,
        margin: " 0.625rem 0",
      }}
    >
      <div id="scroll" style={{ width: "100%", height: "22.5rem" }}></div>
    </BorderBox12>
  );
}

export default ScrollRankingList;
