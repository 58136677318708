import { useState, useEffect } from "react";
import { BorderBox12 } from "@jiaminghi/data-view-react";
import "echarts/theme/macarons";
import { AnimatedCounter } from "react-animated-counter";
import { CardBox, OrderPrice } from "../style";
import { getBasicApi } from "@/api/rightApi";
import { startTimer, stopTimer } from "@/utils/formatTime";
import { Basic_Data_KEY } from "@/contants/contants";
import { getLocal, setLocal } from "@/utils/LocalStorage";
function Card() {
  const [randomValues, setChartData] = useState({
    orders: 0,
    drivers: 0,
    shippers: 0,
    trucks: 0,
    totalAmount: 0,
  });

  const formatrandomValues = (data: {
    drivers: number;
    shippers: number;
    trucks: number;
    orders: number;
    totalAmount: number;
  }) => {
    const formattedData = {
      drivers: data.drivers,
      shippers: data.shippers,
      trucks: data.trucks,
      orders: data.orders,
      totalAmount: data.totalAmount * 3,
    };
    return formattedData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getBasicApi();
        if (res.data.code === "0") {
          const formattedData = formatrandomValues(res.data.data);
          const localChartData = getLocal(Basic_Data_KEY);
          if (
            JSON.stringify(formattedData) !== JSON.stringify(localChartData)
          ) {
            setChartData(formattedData);
            setLocal(Basic_Data_KEY, formattedData);
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          if (
            error.message.includes("timeout") ||
            error.message === "Network Error"
          ) {
            /* empty */
          }
        }
      }
    };
    const localChartData = getLocal(Basic_Data_KEY);
    if (localChartData) {
      setChartData(localChartData);
    }
    fetchData();
    startTimer(fetchData);
    return () => {
      stopTimer();
    };
  }, []);
  return (
    <BorderBox12
      className="right-top"
      style={{
        height: "18.75rem",
        margin: " 0.625rem 0",
      }}
    >
      <CardBox>
        <div className="container">
          <div className="box">
            <span className="box-title">订单统计(单)</span>
            <div className="box-value">
              <div style={{ display: "inline-block" }}>
                <AnimatedCounter
                  value={randomValues?.orders}
                  color="#2ec7c9"
                  fontSize="20px"
                  decimalPrecision={0}
                />
              </div>
            </div>
          </div>

          <div className="box">
            <span className="box-title">货主统计(位)</span>
            <div className="box-value">
              <div style={{ display: "inline-block" }}>
                <AnimatedCounter
                  value={randomValues?.shippers}
                  color="#2ec7c9"
                  fontSize="20px"
                  decimalPrecision={0}
                />
              </div>
            </div>
          </div>
          <div className="box">
            <span className="box-title">司机统计(位)</span>
            <div className="box-value">
              <div style={{ display: "inline-block" }}>
                <AnimatedCounter
                  value={randomValues?.drivers}
                  color="#2ec7c9"
                  fontSize="20px"
                  decimalPrecision={0}
                />
              </div>
            </div>
          </div>
          <div className="box">
            <span className="box-title">货车统计(辆)</span>
            <div className="box-value">
              <div style={{ display: "inline-block" }}>
                <AnimatedCounter
                  value={randomValues?.trucks}
                  color="#2ec7c9"
                  fontSize="20px"
                  decimalPrecision={0}
                />
              </div>
            </div>
          </div>
          <OrderPrice>
            <div className="order_price_title">订单金额(元)</div>
            <div className="order_price_box">
              <AnimatedCounter
                value={randomValues?.totalAmount}
                decimalPrecision={2}
                color="#2EC7C9"
                fontSize="2.5rem"
              />
            </div>
          </OrderPrice>
        </div>
      </CardBox>
    </BorderBox12>
  );
}

export default Card;
