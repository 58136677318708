import { useEffect } from "react";
import "echarts/map/js/china";
import * as echarts from "echarts";
import { useTruckEffect } from "./mapData/truck_effect";
import { useShipEffect } from "./mapData/ship_effect";
import { useCargoandOrderEffect } from "./mapData/cargo_order_effect";
function Map() {
  // 货车
  const { truckEffect } = useTruckEffect();
  // 货主
  const { shipEffect } = useShipEffect();
  // 货物和订单
  const { cargoEffect, orderEffect, orderStartEffect, orderEndEffect } =
    useCargoandOrderEffect();
  useEffect(() => {
    const chartContainer = document.getElementById("map") as HTMLDivElement;
    const chart = echarts.init(chartContainer, "macarons");
    const option = {
      animation: false,
      legend: {
        show: true,
        top: "10",
        left: "left",
        textStyle: {
          color: "#fff",
        },
        orient: "vertical",
        selectedMode: "multiple",
      },
      geo: {
        nameMap: {
          China: "中国",
        },
        map: "china",
        label: {
          show: true,
          color: "rgba(255, 255, 255, 0.7)",
          emphasis: {
            show: true,
            color: "#fff",
          },
        },
        zoom: 1.2,
        roam: true, //拖动
        itemStyle: {
          normal: {
            borderColor: "rgba(255,209,163, .3)",
            areaColor: "rgba(0, 179, 244,.1)",
            borderWidth: 0.9,
            shadowBlur: 5,
            shadowColor: "#443DC5",
          },
          emphasis: {
            borderColor: "#FFD1A3",
            areaColor: "rgba(102,105,240,.3)",
            borderWidth: 1,
            shadowBlur: 5,
            shadowColor: "rgba(135,138,255,.5)",
          },
        },
      },
      tooltip: {
        trigger: "item",
        confine: false,
        backgroundColor: "rgba(50, 50, 50, 0.7)",
        triggerOn: "click",
        extraCssText: "box-shadow: 0 0 0 rgba(0, 0, 0, 0);",
        zIndex: 400,
      },
      series: [
        truckEffect,
        cargoEffect,
        orderEffect,
        shipEffect,
        orderStartEffect,
        orderEndEffect,
      ],
    };

    if (chart) {
      chart.setOption(option as object);
    }

    return () => {
      if (chart) {
        chart.clear();
        chart.dispose();
      }
    };
  }, [
    cargoEffect,
    orderEffect,
    orderStartEffect,
    orderEndEffect,
    truckEffect,
    shipEffect,
  ]);

  return (
    <div>
      <div id="map" style={{ height: "41.08rem" }} />
    </div>
  );
}

export default Map;
