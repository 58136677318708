import { useEffect, useState } from "react";
import * as echarts from "echarts/lib/echarts";
import { BorderBox12 } from "@jiaminghi/data-view-react";
import "echarts/theme/macarons";
import { getRegistrationApi } from "@/api/leftApi";
import { startTimer, stopTimer } from "@/utils/formatTime";
import { setNotopt } from "@/utils/notopt";
import { Number_registrations_KEY } from "@/contants/contants";
import { NumberRegistrationsChartOption } from "./option";
import { getLocal, setLocal } from "@/utils/LocalStorage";
interface RegistrationData {
  date: (string | number)[];
  registration: (string | number)[];
  verification: (string | number)[];
}
function TechBarChart() {
  const [chartData, setChartData] = useState<{
    date: (string | number)[];
    registration: (string | number)[];
    verification: (string | number)[];
  }>({
    registration: [],
    verification: [],
    date: [],
  });
  useEffect(() => {
    const chartContainer = document.getElementById(
      "tech-bar-chart"
    ) as HTMLDivElement;
    const chart = echarts.init(chartContainer, "macarons");
    const formatChartData = (data: RegistrationData) => {
      const formattedData: {
        registration: number[];
        verification: number[];
        date: string[];
      } = {
        registration: [],
        verification: [],
        date: [],
      };
      const registrationData = data.registration;
      const verificationData = data.verification;

      for (let i = 0; i < registrationData.length; i += 2) {
        const rawDate = registrationData[i].toString();
        const formattedDate = `${rawDate.slice(0, 4)}-${rawDate.slice(
          4,
          6
        )}-${rawDate.slice(6, 8)}`;
        formattedData.date.push(formattedDate);
        formattedData.registration.push(
          (registrationData[i + 1] as number) + 20
        );
      }

      for (let i = 0; i < verificationData.length; i += 2) {
        formattedData.verification.push(
          (verificationData[i + 1] as number) + 20
        );
      }

      return formattedData;
    };
    chart.showLoading();
    const fetchData = async () => {
      try {
        const res = await getRegistrationApi();
        if (res.data.code === "0") {
          const formattedData = formatChartData(res.data.data);
          const localChartData = getLocal(Number_registrations_KEY);
          if (
            JSON.stringify(formattedData) !== JSON.stringify(localChartData)
          ) {
            setChartData(formattedData);
            setLocal(Number_registrations_KEY, formattedData);
          } else {
            chart.hideLoading();
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          if (
            error.message.includes("timeout") ||
            error.message === "Network Error"
          ) {
            chart.hideLoading();
          }
        }
      }
    };
    const localChartData = getLocal(Number_registrations_KEY);
    if (localChartData) {
      setChartData(localChartData);
    }
    fetchData();
    startTimer(fetchData);
    return () => {
      stopTimer();
      chart.dispose();
    };
  }, []);

  useEffect(() => {
    const chartContainer = document.getElementById(
      "tech-bar-chart"
    ) as HTMLDivElement;
    const chart = echarts.init(chartContainer, "macarons");
    if (chartData.date.length < 1) {
      setNotopt(chart, "糟糕！注册量数据出现错误");
    } else {
      const option = NumberRegistrationsChartOption(chartData);
      chart.setOption(option as object);
    }
    return () => {
      chart.dispose();
    };
  }, [chartData]);

  return (
    <BorderBox12 style={{ margin: "0.625rem 0" }}>
      <div
        id="tech-bar-chart"
        style={{ width: "100%", height: "18.75rem" }}
      ></div>
    </BorderBox12>
  );
}

export default TechBarChart;
