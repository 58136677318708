import { useEffect, useState } from "react";
import * as echarts from "echarts/lib/echarts";
import { BorderBox12 } from "@jiaminghi/data-view-react";
import { getOrderApi } from "@/api/leftApi";
import { startTimer, stopTimer } from "@/utils/formatTime";
import { setNotopt } from "@/utils/notopt";
import { Order_Status_KEY } from "@/contants/contants";
import { OrderStatusChartOption } from "./option";
import { getLocal, setLocal } from "@/utils/LocalStorage";
interface ChartData {
  [key: string]: number;
}
function PieChartLeft() {
  const [chartData, setChartData] = useState<{ name: string; value: number }[]>(
    []
  );

  useEffect(() => {
    const chartContainer = document.getElementById(
      "pie-chart-container"
    ) as HTMLDivElement;
    const chart = echarts.init(chartContainer);

    const formatChartData = (
      data: ChartData
    ): { name: string; value: number }[] => {
      const formattedData = Object.keys(data).map((item) => ({
        name: item,
        value: data[item],
      }));
      return formattedData;
    };

    chart.showLoading();
    const fetchData = async () => {
      try {
        const res = await getOrderApi();
        if (res.data.code === "0") {
          const formattedData = formatChartData(res.data.data);
          const localChartData = getLocal(Order_Status_KEY);
          if (
            JSON.stringify(formattedData) !== JSON.stringify(localChartData)
          ) {
            setChartData(formattedData);
            setLocal(Order_Status_KEY, formattedData);
          } else {
            chart.hideLoading();
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          if (
            error.message.includes("timeout") ||
            error.message === "Network Error"
          ) {
            chart.hideLoading();
          }
        }
      }
    };
    const localChartData = getLocal(Order_Status_KEY);
    if (localChartData) {
      setChartData(localChartData);
    }
    fetchData();
    startTimer(fetchData);
    return () => {
      stopTimer();
    };
  }, []);

  useEffect(() => {
    const chartContainer = document.getElementById(
      "pie-chart-container"
    ) as HTMLDivElement;
    const chart = echarts.init(chartContainer);
    if (chartData.length < 1) {
      setNotopt(chart, "糟糕！订单状态数据出现错误");
    } else {
      const option = OrderStatusChartOption(chartData);
      chart.setOption(option as object);
    }
    return () => {
      chart.dispose();
    };
  }, [chartData]);

  return (
    <BorderBox12 className="tech-bar-chart-container">
      <div
        id="pie-chart-container"
        style={{ width: "100%", height: "18.75rem" }}
      />
    </BorderBox12>
  );
}

export default PieChartLeft;
