import PieChart from "./echarts/user_active_effect";
import TreeChartandLine from "./echarts/shipments_effect";
import Chatbot from "./echarts/basic_data_effect";
function rightPage() {
  return (
    <div>
      <Chatbot></Chatbot>
      <TreeChartandLine></TreeChartandLine>
      <PieChart></PieChart>
    </div>
  );
}

export default rightPage;
