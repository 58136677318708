import { useEffect, useState } from "react";
import * as echarts from "echarts/lib/echarts";
import { BorderBox12 } from "@jiaminghi/data-view-react";
import { startTimer, stopTimer } from "@/utils/formatTime";
import { getOrderStatsApi } from "@/api/leftApi";
import { setNotopt } from "@/utils/notopt";
import { Take_Orders_KEY } from "@/contants/contants";
import { TakeOrdersChartOption } from "./option";
import { getLocal, setLocal } from "@/utils/LocalStorage";
interface LineChart {
  orderStats: (string | number)[];
}
function LineChart() {
  const [chartData, setChartData] = useState<{
    orderStats: number[];
    date: string[];
  }>({
    orderStats: [],
    date: [],
  });
  const formatChartData = (
    data: LineChart
  ): { orderStats: number[]; date: string[] } => {
    const formattedData: { orderStats: number[]; date: string[] } = {
      orderStats: [],
      date: [],
    };
    const chartData = data.orderStats;
    for (let i = 0; i < chartData.length; i += 2) {
      const rawDate = chartData[i].toString();
      const formattedDate = `${rawDate.slice(0, 4)}-${rawDate.slice(
        4,
        6
      )}-${rawDate.slice(6, 8)}`;
      formattedData.date.push(formattedDate);
      formattedData.orderStats.push(chartData[i + 1] as number+20);
    }
    return formattedData;
  };
  useEffect(() => {
    const chartContainer = document.getElementById(
      "line-chart-container"
    ) as HTMLDivElement;
    const chart = echarts.init(chartContainer, "macarons");
    chart.showLoading();
    const fetchData = async () => {
      try {
        const res = await getOrderStatsApi();
        if (res.data.code === "0") {
          const formattedData = formatChartData(res.data.data);
          const localChartData = getLocal(Take_Orders_KEY);
          if (
            JSON.stringify(formattedData) !== JSON.stringify(localChartData)
          ) {
            setChartData(formattedData);
            setLocal(Take_Orders_KEY, formattedData);
          }
          chart.hideLoading();
        }
      } catch (error) {
        if (error instanceof Error) {
          if (
            error.message.includes("timeout") ||
            error.message === "Network Error"
          ) {
            chart.hideLoading();
          }
        }
      }
    };
    const localChartData = getLocal(Take_Orders_KEY);
    if (localChartData) {
      setChartData(localChartData);
    }
    fetchData();
    startTimer(fetchData);
    return () => {
      stopTimer();
    };
  }, []);
  useEffect(() => {
    const chartContainer = document.getElementById(
      "line-chart-container"
    ) as HTMLDivElement;
    const chart = echarts.init(chartContainer, "macarons");
    if (chartData.orderStats.length < 1) {
      setNotopt(chart, "糟糕！接单统计数据出现错误");
    } else {
      const option = TakeOrdersChartOption(chartData);
      chart.setOption(option as object);
    }
    return () => {
      chart.dispose();
    };
  }, [chartData]);

  return (
    <BorderBox12 style={{ margin: "0.625rem 0" }}>
      <div
        id="line-chart-container"
        style={{ width: "100%", height: "22.5rem" }}
      />
    </BorderBox12>
  );
}

export default LineChart;
