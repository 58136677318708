import * as echarts from "echarts";
import { getDetailApi } from "@/api/centerApi";
import { toast } from "react-toastify";
interface Cargo {
  id: string;
  coords: [number, number];
}

interface Order {
  id: string;
  coords: [number, number][];
}

interface ChartData {
  cargoes: Cargo[];
  orders?: Order[];
}

interface ErrorResponse {
  message: string;
  code: string;
}
// 货物分布
export const getCargoEffect = (chartData: ChartData) => {
  return {
    name: "货物分布",
    type: "scatter",
    coordinateSystem: "geo",
    zlevel: 2,
    symbol: "image://" + require("@/assets/image/cargo.png"),
    tooltip: {
      trigger: "item",
      show: true,
      backgroundColor: "transparent",
      borderColor: "transparent",
      formatter: function (
        params: { data: { id: string } },
        ticket: string,
        callback: (arg0: string, arg1: string) => void
      ) {
        const cargoId = params.data.id;
        const type = 0;

        getDetailApi(cargoId, type)
          .then((res) => {
            const listCargo = res.data.data;
            const unitLabel =
              listCargo.single_price !== null ? "元/车" : "元/吨";
            const txt = `
          <div style="
          background: rgba(0, 179, 244, 0.7);
          border: 2px solid #2BEDF6;
          border-radius: 8px;
          padding: 10px;
          color: #2BEDF6;
          box-shadow: 0 0 10px rgba(0, 179, 244, 0.5);
          font-family: 'Orbitron', sans-serif;
          text-align: left;
          ">
          <h2 style="font-weights:bold; margin: 10px 0 20px 0;text-align: center;">货物详情</h2>
          <p style="color: #fff; margin: 8px 0;">货物名称：${listCargo.name}</p>
          <p style="color: #fff; margin: 8px 0;">单价：${listCargo.unit_price}${unitLabel}</p>
          <p style="color: #fff; margin: 8px 0;">发货地址：${listCargo.from.province}${listCargo.from.city}${listCargo.from.district}${listCargo.from.address}</p>
          <p style="color: #fff; margin: 8px 0;">收货地址：${listCargo.to.province}${listCargo.to.city}${listCargo.to.district}${listCargo.to.address}</p>
          <p style="color: #fff; margin: 8px 0;">货物图片：<img style="width: 15.625rem;height: 9.375rem;vertical-align:middle;" src="${listCargo.image}" /></p>
          </div>
          `;
            callback(ticket, txt);
          })
          .catch((error) => {
            if (
              error.message.includes("timeout") ||
              error.code === "ERR_NETWORK" ||
              error.message === "Network Error"
            ) {
              toast.error("货物详情出错啦", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          });
        return `
           正在努力加载中...
        `;
      },
    },
    data: chartData.cargoes.map((cargo) => ({
      value: cargo.coords,
      id: cargo.id,
    })),
    rippleEffect: {
      brushType: "stroke",
    },
    color: "#fff",
    symbolSize: 15,
    showEffectOn: "render",
    itemStyle: {
      normal: {
        color: "#D87A80",
      },
    },
  };
};

// 订单流动
export const getOrderEffect = (chartData: { orders?: Order[] }) => {
  return {
    name: "订单流动",
    type: "lines",
    coordinateSystem: "geo",
    zlevel: 2,
    zoom: 1,
    tooltip: {
      trigger: "item",
      show: true,
      backgroundColor: "transparent",
      borderColor: "transparent",
      formatter: function (
        params: { data: { id: string } },
        ticket: string,
        callback: (arg0: string, arg1: string) => void
      ) {
        const oraderId = params.data.id;
        const type = 1;
        getDetailApi(oraderId, type)
          .then((res) => {
            const listDataCargo = res.data.data.cargo;
            const listData = res.data.data;
            const unitLabel =
              listData.single_price !== null ? "元/车" : "元/吨";
            const txt = `
          <div style="
          background: rgba(0, 179, 244, 0.7);
          border: 2px solid #2BEDF6;
          border-radius: 8px;
          padding: 10px;
          color: #2BEDF6;
          box-shadow: 0 0 10px rgba(0, 179, 244, 0.5);
          font-family: 'Orbitron', sans-serif;
          text-align: left;
          ">
          <h2 style="color:#00B3F4 margin: 10px 0 20px 0;text-align: center;">订单详情</h2>
          <p style="color: #fff; margin: 8px 0;">货物名称: ${listDataCargo.name}</p>
          <p style="color: #fff; margin: 8px 0;">接单车牌号: ${listData.truck[0].plate}</p>
          <p style="color: #fff; margin: 8px 0;">发货地址：${listDataCargo.from.province}${listDataCargo.from.city}${listDataCargo.from.district}${listDataCargo.from.address}</p>
          <p style="color: #fff; margin: 8px 0;">收货地址：${listDataCargo.to.province}${listDataCargo.to.city}${listDataCargo.to.district}${listDataCargo.to.address}</p>
          <p style="color: #fff; margin: 8px 0;">单价：${listData.unit_price}${unitLabel}</p>
          <p style="color: #fff; margin: 8px 0;">装货重量：${listData.weights.expect_load}吨</p>
          </div>
          `;
            callback(ticket, txt);
          })
          .catch((error: ErrorResponse) => {
            if (
              error.message.includes("timeout") ||
              error.code === "ERR_NETWORK" ||
              error.message === "Network Error"
            ) {
              toast.error("订单详情出错啦", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          });
        return `
           正在努力加载中...
        `;
      },
    },
    large: true,
    data: chartData.orders?.map((order: Order) => ({
      coords: order.coords,
      id: order.id,
    })),
    effect: {
      show: true,
      constantSpeed: 7, //飞行速度 值越大越快
      symbol: "pin", //自定义图标
      symbolSize: 10, //飞行图标大小
      trailLength: 0,
      period: 1,
    },
    lineStyle: {
      normal: {
        color: new echarts.graphic.LinearGradient(
          0,
          0,
          0,
          1,
          [
            {
              offset: 0,
              color: "#00B3F4",
            },
            {
              offset: 1,
              color: "#F58158",
            },
          ],
          false //飞线是否关闭渐变色
        ),
        width: 2, //飞线宽度
        opacity: 0.5, //飞线透明度
        curveness: 0.1,
      },
    },
  };
};

// 订单起点
export const getOrderStartEffect = (chartData: ChartData) => {
  return {
    name: "订单起点",
    type: "effectScatter",
    tooltip: {
      show: false,
    },
    coordinateSystem: "geo",
    zlevel: 2,
    rippleEffect: {
      brushType: "stroke",
    },
    data:
      chartData.orders?.map((order: Order) => ({
        value: order.coords[0],
      })) || [],
    color: "#fff",
    symbolSize: 8,
    showEffectOn: "render",
    itemStyle: {
      normal: {
        color: "#2EC7C9",
      },
    },
  };
};

// 订单终点
export const getOrderEndEffect = (chartData: ChartData) => {
  return {
    name: "订单终点",
    type: "effectScatter",
    tooltip: {
      show: false,
    },
    coordinateSystem: "geo",
    zlevel: 2,
    rippleEffect: {
      brushType: "stroke",
    },
    data:
      chartData.orders?.map((order: Order) => ({
        value: order.coords[1],
      })) || [],
    color: "#fff",
    symbolSize: 8,
    showEffectOn: "render",
    itemStyle: {
      normal: {
        color: "#FBE30E",
      },
    },
  };
};
