import { useEffect, useState } from "react";
import { getCommonApi } from "@/api/centerApi";
import { MAP_DATA_KEY } from "@/contants/contants";
import { startTimer, stopTimer } from "@/utils/formatTime";
import {
  getCargoEffect,
  getOrderEffect,
  getOrderStartEffect,
  getOrderEndEffect,
} from "./cargo_order_option";
import { getLocal, setLocal } from "@/utils/LocalStorage";

interface Cargo {
  id: string;
  coords: [number, number];
}

interface Order {
  id: string;
  coords: [number, number][];
}
interface RawCargoData {
  positions: { long: number; lat: number }[];
  id: string;
}
interface RawOrderData {
  positions: { long: number; lat: number }[];
  id: string;
}

interface RawData {
  cargoes: RawCargoData[];
  orders: RawOrderData[];
}

interface FormattedData {
  cargoes: Cargo[];
  orders: Order[];
}

export const useCargoandOrderEffect = () => {
  const [chartData, setChartData] = useState<FormattedData>({
    cargoes: [],
    orders: [],
  });

  const formatRandomValues = (data: RawData): FormattedData => {
    const formattedData: FormattedData = {
      cargoes: data.cargoes.map((cargo) => {
        const firstPosition = cargo.positions[0];
        return {
          id: cargo.id,
          coords: [firstPosition.long, firstPosition.lat],
        };
      }),
      orders: data.orders.map((order) => {
        const positions = order.positions;
        return {
          id: order.id,
          coords: positions.map((position) => [position.long, position.lat]),
        };
      }),
    };
    return formattedData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCommonApi();
        if (res.data.code === "0") {
          const formattedData = formatRandomValues(res.data.data);
          const localChartData = getLocal(MAP_DATA_KEY);
          if (
            JSON.stringify(formattedData) !== JSON.stringify(localChartData)
          ) {
            setChartData(formattedData);
            setLocal(MAP_DATA_KEY, formattedData);
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          if (
            error.message.includes("timeout") ||
            error.message === "Network Error"
          ) {
            /* empty */
          }
        }
      }
    };

    const localChartData = getLocal(MAP_DATA_KEY);
    if (localChartData) {
      setChartData(localChartData);
    }
    fetchData();
    startTimer(fetchData);
    return () => {
      stopTimer();
    };
  }, []);

  const cargoEffect = getCargoEffect(chartData);
  const orderEffect = getOrderEffect(chartData);
  const orderStartEffect = getOrderStartEffect(chartData);
  const orderEndEffect = getOrderEndEffect(chartData);
  return { cargoEffect, orderEffect, orderStartEffect, orderEndEffect };
};
