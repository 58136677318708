import { useEffect, useState } from "react";
import * as echarts from "echarts";
import { BorderBox12 } from "@jiaminghi/data-view-react";
import "echarts/theme/macarons";
import { getCargoStatsApi } from "@/api/rightApi";
import { startTimer, stopTimer } from "@/utils/formatTime";
import { setNotopt } from "@/utils/notopt";
import { Shipments_KEY } from "@/contants/contants";
import { ShipmentsChartOption } from "./option";
import { getLocal, setLocal } from "@/utils/LocalStorage";

interface CargoStatsData {
  cargoStats: (string | number)[];
}
function TreeChartand() {
  const [chartData, setChartData] = useState<{
    cargoStats: number[];
    date: string[];
  }>({
    cargoStats: [],
    date: [],
  });

  const formatChartData = (
    data: CargoStatsData
  ): { cargoStats: number[]; date: string[] } => {
    const formattedData: { cargoStats: number[]; date: string[] } = {
      cargoStats: [],
      date: [],
    };

    const cargoStats = data.cargoStats;
    for (let i = 0; i < cargoStats.length; i += 2) {
      const rawDate = cargoStats[i].toString();
      const formattedDate = `${rawDate.slice(0, 4)}-${rawDate.slice(
        4,
        6
      )}-${rawDate.slice(6, 8)}`;
      formattedData.date.push(formattedDate);
      formattedData.cargoStats.push((cargoStats[i + 1] as number) + 20);
    }
    return formattedData;
  };
  useEffect(() => {
    const chartContainer = document.getElementById(
      "TreeChart-and-LineChart-right"
    ) as HTMLDivElement;
    const chart = echarts.init(chartContainer, "macarons");
    chart.showLoading();
    const fetchData = async () => {
      try {
        const res = await getCargoStatsApi();
        if (res.data.code === "0") {
          const formattedData = formatChartData(res.data.data);
          const localChartData = getLocal(Shipments_KEY);
          if (
            JSON.stringify(formattedData) !== JSON.stringify(localChartData)
          ) {
            setChartData(formattedData);
            setLocal(Shipments_KEY, formattedData);
          } else {
            chart.hideLoading();
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          if (
            error.message.includes("timeout") ||
            error.message === "Network Error"
          ) {
            chart.hideLoading();
          }
        }
      }
    };
    const localChartData = getLocal(Shipments_KEY);
    if (localChartData) {
      setChartData(localChartData);
    }
    fetchData();
    startTimer(fetchData);
    return () => {
      stopTimer();
    };
  }, []);

  useEffect(() => {
    const chartContainer = document.getElementById(
      "TreeChart-and-LineChart-right"
    ) as HTMLDivElement;
    const chart = echarts.init(chartContainer, "macarons");
    if (chartData.date.length < 1) {
      setNotopt(chart, "糟糕！发货统计数据出现错误");
    } else {
      const option = ShipmentsChartOption(chartData);
      chart.setOption(option as object);
    }
    return () => {
      chart.dispose();
    };
  }, [chartData]);

  return (
    <div>
      <BorderBox12 style={{ margin: " 0.625rem 0" }}>
        <div
          id="TreeChart-and-LineChart-right"
          style={{ width: "100%", height: "18.75rem" }}
        />
      </BorderBox12>
    </div>
  );
}

export default TreeChartand;
