import * as echarts from "echarts";
// 发货统计(15天)
export const ShipmentsChartOption = (chartData: {
  cargoStats: number[];
  date: string[];
}) => {
  return {
    animation: false,
    title: {
      text: "发货统计(15天)",
      x: "left",
      top: "5%",
      left: "5%",
      textStyle: {
        color: "rgba(0,179,244)",
        fontWeight: "bold",
      },
    },
    legend: {
      right: "10%",
      top: "5%",
      textStyle: {
        color: "#c0c9d2",
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      textStyle: {
        color: "#fff",
      },
      backgroundColor: "rgba(0,179,244,0.7)",
    },
    grid: {
      top: "90",
      bottom: "20",
      containLabel: true,
    },
    xAxis: {
      data: chartData.date,
      inverse: true,
    },
    yAxis: {
      splitArea: false,
      splitLine: {
        lineStyle: {
          color: "rgba(0,179,244,0.9)",
        },
      },
      axisLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        name: "发货量",
        type: "bar",
        barWidth: "15",
        itemStyle: {
          barBorderRadius: [50, 50, 0, 0],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#00B3F4" },
            { offset: 1, color: "#43eec6" },
          ]),
        },
        label: {
          position: "top",
          show: true,
          textStyle: {
            color: "#2EC7C9",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        data: chartData.cargoStats,
      },
    ],
  };
};

// 用户活跃(15天)
export const UserActiveChartOption = (chartData: {
  onlStats: number[];
  date: string[];
}) => {
  return {
    animation: false,
    title: {
      text: "用户活跃(15天)",
      x: "left",
      left: "5%",
      top: "5%",
      textStyle: {
        color: "rgba(0,179,244)",
        fontWeight: "bold",
      },
    },
    grid: {
      top: "85",
      bottom: "30",
      containLabel: true,
    },
    legend: {
      right: "10%",
      top: "5%",
      textStyle: {
        color: "#c0c9d2",
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      textStyle: {
        color: "#fff",
      },
      backgroundColor: "rgba(0,179,244,0.5)",
    },

    xAxis: {
      type: "category",
      data: chartData.date,
      inverse: true,
      boundaryGap: false,
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      splitArea: false,
      axisLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: "rgba(0,179,244,0.9)",
        },
      },
    },
    series: [
      {
        name: "活跃用户",
        data: chartData.onlStats,
        type: "line",
        label: {
          show: true,
          position: "top",
          textStyle: {
            color: "#2EC7C9",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        itemStyle: { color: "#00ca95" },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(
            0,
            0,
            0,
            1,
            [
              {
                offset: 0,
                color: "rgba(46,199,201,0.3)",
              },
              {
                offset: 1,
                color: "rgba(46,199,201,0.1)",
              },
            ],
            false
          ),
          shadowColor: "rgba(46,199,201,0.9)",
          shadowBlur: 20,
        },
        lineStyle: {
          color: "#2EC7C9",
          width: 2,
        },
      },
    ],
  };
};
