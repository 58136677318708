import { useEffect, useState } from "react";
import { getUsersRoleApi } from "@/api/centerApi";
import { SHIP_DATA_KEY } from "@/contants/contants";
import { startTimer, stopTimer } from "@/utils/formatTime";
import { getLocal, setLocal } from "@/utils/LocalStorage";

interface ShipLocation {
  longitude: number;
  latitude: number;
}
interface Ship {
  location: ShipLocation;
}
interface ChartDataShip {
  ships: Ship[];
}

export const useShipEffect = () => {
  const [chartDataShip, setChartDataShip] = useState<ChartDataShip>({
    ships: [],
  });

  const formatShipValue = (data: Ship[]) => {
    const shipData: ChartDataShip = {
      ships: data,
    };
    return shipData;
  };

  useEffect(() => {
    const fetchData = async () => {
      const role = 1;
      try {
        const res = await getUsersRoleApi(role);
        if (res.data.code === "0") {
          const formattedData = formatShipValue(res.data.data);
          const localChartData = getLocal(SHIP_DATA_KEY);
          if (
            JSON.stringify(formattedData) !== JSON.stringify(localChartData)
          ) {
            setChartDataShip(formattedData);
            setLocal(SHIP_DATA_KEY, formattedData);
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          if (
            error.message.includes("timeout") ||
            error.message === "Network Error"
          ) {
            /* empty */
          }
        }
      }
    };

    const localChartData = getLocal(SHIP_DATA_KEY);
    if (localChartData) {
      setChartDataShip(localChartData);
    }

    fetchData();
    startTimer(fetchData);

    return () => {
      stopTimer();
    };
  }, []);
  const getShipEndEffect = () => {
    return {
      name: "货主分布",
      type: "scatter",
      tooltip: {
        show: false,
      },
      coordinateSystem: "geo",
      zlevel: 2,
      rippleEffect: {
        brushType: "stroke",
      },
      data: chartDataShip.ships.map((item) => {
        return {
          value: [item.location.longitude, item.location.latitude],
        };
      }),
      color: "#fff",
      symbolSize: 15,
      showEffectOn: "render",
      itemStyle: {
        normal: {
          color: "#ED868C",
        },
      },
    };
  };
  const shipEffect = getShipEndEffect();
  return { shipEffect };
};
