import request from "@/utils/request";

// 满意度
export function getChatbotagreeApi() {
  return request({
    url: "/chatbot/automation/chatbotagree",
    method: "GET",
  });
}

// 每日回复
export function getChatbotindexApi() {
  return request({
    url: "/chatbot/automation/chatbotindex?daynum=7",
    method: "GET",
  });
}

// 咨询货物
export function getChatbotgoodrankApi() {
  return request({
    url: "/chatbot/automation/chatbotgoodrank",
    method: "GET",
  });
}
