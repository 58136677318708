import styled from "styled-components";

export const TopPage = styled.div`
  .top-sidebar-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0.3125rem;
  }
  .left-decorations {
    display: flex;
    height: 5rem;
  }
  .right-decorations {
    display: flex;
    height: 5rem;
  }
  .center-content {
    font-size: 1.5rem;
    height: 5rem;
  }
  .current-time {
    position: absolute;
    top: 1.25rem;
    color: #00b3f4;
    right: 1.25rem;
    font-size: 1.125rem;
    font-weight: bold;
  }
  .chatBot {
    position: absolute;
    top: 1.25rem;
    color: #00b3f4;
    left: 1.25rem;
    font-size: 1.125rem;
    font-weight: bold;
  }

  h3 {
    color: #00b3f4;
    font-size: 2.25rem;
    text-align: justify;
    text-align-last: justify;
    width: 34.375rem;
    line-height: 0;
  }
`;
