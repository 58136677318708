import LineChart from "./echarts/take_orders_effect";
import PieChartleft from "./echarts/order_status_effect";
import TreeChart from "./echarts/registrations_effect";
function leftPage() {
  return (
    <div>
      <TreeChart></TreeChart>
      <PieChartleft></PieChartleft>
      <LineChart></LineChart>
    </div>
  );
}

export default leftPage;
