import styled from "styled-components";
export const CardBox = styled.div`
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px;
  }
  .box {
    background-color: rgba(0, 179, 244, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    color: #2ec7c9;
  }
  .box-title {
    font-size: 16px;
    color: #00b3f4;
    letter-spacing: 0.3125rem;
    margin-top: 1.25rem;
    font-weight: bold;
  }

  .box-value {
    margin-top: 1.25rem;
    font-weight: bold;
    font-size: 18px;
  }
`;
export const OrderPrice = styled.div`
  .order_price_box {
    background-color: rgba(0, 179, 244, 0.1);
    margin-top: 0.625rem;
    border-radius: 0.625rem;
    width: 27.8125rem;
    height: 8.5625rem;
    display: flex;
  }
  .order_price_title {
    position: absolute;
    top: 9.6875rem;
    left: 1.875rem;
    font-size: 1rem;
    color: #00b3f4;
    font-weight: bold;
  }

  .ticker-column-container {
    margin: 0 5px;
    font-weight: bold;
  }
`;
