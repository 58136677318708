export const MAP_DATA_KEY = "MAP_DATA_KEY";
export const TRUCK_DATA_KEY = "TRUCK_DATA_KEY";
export const SHIP_DATA_KEY = "SHIP_DATA_KEY";
export const Number_registrations_KEY = "Number_registrations_KEY";
export const Order_Status_KEY = "Order_Status_KEY";
export const Take_Orders_KEY = "Take_Orders_KEY";
export const Shipments_KEY = "Shipments_KEY";
export const User_Active_KEY = "User_Active_KEY";
export const Basic_Data_KEY = "Basic_Data_KEY";
export const Cargo_Ranking_KEY = "Cargo_Ranking_KEY";
export const Daily_Responses_KEY = "Daily_Responses_KEY";
export const Satisfaction_KEY = "Satisfaction_KEY";
