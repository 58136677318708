import { useState, useEffect } from "react";
import Keycloak from "keycloak-js";
import { ScaleViewContainer } from "tgg-large-screen";
import { AppBox, ContainerBox, HeaderTopImage, PageBj } from "./AppStyle";
import { Loading } from "@jiaminghi/data-view-react";
import TopSidebar from "./component/topPage";
import LeftPage from "./component/leftPage";
import CenterPage from "./component/centerPage";
import RightPage from "./component/rightPage";
import KeycloakManager from "./utils/keycloakManager";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const keycloak = new Keycloak("./keycloak.json");

    keycloak
      .init({ onLoad: "login-required", checkLoginIframe: false })
      .then((authenticated) => {
        if (authenticated) {
          KeycloakManager.setInstance(keycloak);
          setLoading(false);

          // Set up token refresh
          const refreshInterval = setInterval(() => {
            keycloak.updateToken(70).catch(() => {
              keycloak.logout();
            });
          }, 60000); // Check every minute

          // Clean up interval on component unmount
          return () => clearInterval(refreshInterval);
        } else {
          keycloak.login();
        }
      });

    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <ScaleViewContainer
      config={{
        width: 1920,
        height: 1080,
        scaleType: "FULL_SCREEN",
      }}
    >
      <AppBox>
        <PageBj>
          {loading && (
            <Loading className="loading-overlay">正在努力加载中</Loading>
          )}
          <div className="app-container">
            <HeaderTopImage>
              <div className="top-sidebar">
                <TopSidebar />
              </div>
            </HeaderTopImage>
            <ContainerBox>
              <div className="containerBox">
                <div className="left-sidebar">
                  <LeftPage />
                </div>
                <div className="content">
                  <CenterPage />
                </div>
                <div className="right-sidebar">
                  <RightPage />
                </div>
              </div>
            </ContainerBox>
          </div>
        </PageBj>
      </AppBox>
    </ScaleViewContainer>
  );
}

export default App;
