import styled from "styled-components";
import pageHeader from "@/assets/image/head_bg.png";
import pageBj from "@/assets/image/bodyBj.jpg";
export const ContainerBox = styled.div`
  .containerBox {
    text-align: center;
    display: flex;
    height: 100%;
  }
  .left-sidebar {
    flex: 1.5;
    margin-left: 0.625rem;
  }
  .content {
    flex: 3;
    flex-direction: column;
  }
  .right-sidebar {
    flex: 1.5;
    flex-direction: column;
    margin-right: 0.625rem;
  }
`;

export const AppBox = styled.div`
  padding: 0;
  margin: 0;
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #00e6e6, #8e2de2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    z-index: 9999;
  }
`;

export const HeaderTopImage = styled.div`
  .top-sidebar {
    text-align: center;
    height: 5rem;
    font-size: 2.25rem;
    font-weight: bold;
    background-image: url(${pageHeader});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const PageBj = styled.div`
  .app-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-image: url(${pageBj});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
  }
`;
